import React from "react";
import TextLoop from "react-text-loop";
import Particles from "react-particles-js";
import { Container } from "react-grid-system";

function Intro() {
    return (
        <section className="section section-hero section-hero-1 overlay-image" >
            <Particles
                params={{
                    particles: {
                        number: {
                            value: 300,
                            density: {
                                enable: true,
                            },
                        },
                        size: {
                            value: 5,
                            random: true,
                        },
                        move: {
                            direction: "bottom",
                            out_mode: "out",
                        },
                        line_linked: {
                            enable: false,
                        },
                    },
                    interactivity: {
                        events: {
                            onhover: {
                                enable: true,
                                mode: "bubble"
                            },
                            onclick: {
                                enable: true,
                                mode: "repulse"
                            }
                        },
                        modes: {
                            bubble: {
                                distance: 250,
                                duration: 2,
                                size: 3,
                            },
                            repulse: {
                                distance: 400,
                                duration: 4
                            }
                        }
                    }
                }}
            />
            <div className="display-center">
                <Container>
                    <div className="el-heading">
                        <p className="el-icon">
                            <span className="el-icon-title">I'm Abdurachman Palahuddin.</span>
                        </p>
                        <h2>
                            I'm a{" "}
                            <TextLoop>
                                <span>DevOps Engineer</span>
                                <span>Linux Enthusiast</span>
                            </TextLoop>
                            <br />
                            Based in Jakarta
                        </h2>
			 <div className="divider divider-1"></div>
                         <p align="justify" >Currently fulltime as Cloud & Linux System Administrator working with several tools likes 
                             RHEL/Centos 7, Debian, Proxmox VE , Docker, Kubernetes, Jenkins CI, 
                             Rancher/RKE, ELK Stack, AWS, Azure, Aliyun, Linode, Terraform, Ansible, Bash.
                         </p>

                        <button className="button button-lg button-primary">
                            <span className="wave"></span>
			    <a href="https://id.linkedin.com/in/palahuddin"><span className="text">My Linkedin</span></a>
                        </button>
                        <button className="button button-lg">
                            <span className="text"></span>
                        </button>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default Intro;
